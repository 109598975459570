<template>
    <div class="list-item">
        <div class="item-txt">头像</div>
        <!-- <img class="item-img" v-lazy="pic" alt="" /> -->
        <mmcUploaderAvatar  class="item-img"
            :maxNum="100"
            :imgArr="[pic]"
            @changeend="onPoorImgChange"
        />
        <img class="item-arrow" src="./icon-arrow.png" alt="" />
    </div>
</template>

<script>
import mmcUploaderAvatar from './mmc-uploader-avatar';
import api from '../api'
export default {
    props: ['pic'],
    components: {
        mmcUploaderAvatar,
    },
    methods:{
      onPoorImgChange(s){
        console.log(s)
        api.changeAvatar(s[s.length-1]).then((res)=>{
          console.log(res)
        })
      }
    }
};
</script>

<style lang="less" scoped>
.list-item {
    display: flex;
    padding: 13px 16px;
    background-color: #fff;
    align-items: center;
    .item-img {
        width: 32px;
        height: 32px;
        margin-right: 15px;
        border-radius: 16px;
    }
    .item-txt {
        font-size: 14px;
        color: #333;
        flex: 1;
        text-align: left;
        line-height: 14px;
        // margin-left: 10px;
    }
    .item-arrow {
        width: 7px;
        height: 10px;
    }
}
</style>