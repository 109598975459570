<template>
	<div class="san">
   <!-- {{myname}} -->
		<div class="content">
			
			<sprSuccess v-if="status==0" :upImg="url"  @click="showBigImg"></sprSuccess>
			<sprFail v-if="status==1"  @change="changeImg"></sprFail>
			<sprProcessing v-if="status==2" :progress="progress"></sprProcessing>

		</div>
    
		<!-- <div class="close-btn" @click="removeImg()"></div> -->
	</div>
</template>

<script>
//status:0-成功，1-失败，2-上传中
import sprFail from './spr-fail.vue'
import sprProcessing from './spr-processing.vue'
import sprSuccess from './spr-success.vue'
import store from 'store'
import axios from 'axios'

const REMOVEIMG = 'removeimg'
const CHANGESTATUS = 'changestatus'
const ONEBEGIN = 'onebegin'
const SHOWBIGIMG = 'showbigimg'



export default {

    name: 'uploader-default-san',

    data() {
        return {}
    },
    props: ['status', 'progress','fileObj', 'url', 'myname'],
    methods: {
        upBegin(fo) {

            var evtObj = {
                progress: 0,
                status: 2
            }
            this.$emit(CHANGESTATUS, evtObj)

            var upUrl = `${window.APIBasePath}mmc/oss/upload`;
            console.log(upUrl)
            var localTokenAll = store.get('Mmc-Token');
            var token = localTokenAll ? JSON.parse(localTokenAll).access_token : '';
            var config = {
                headers: {
                    'Content-Type': 'multipart/form-data;charset=utf-8',
                    "Mmc-Token": token,
                    Platform: 'mmc_h5',
                },

                onUploadProgress: (e) => {
                    // console.log('p', e, e.loaded, e.total)
                    var evtObj = {
                        progress: Math.floor(e.loaded*99/e.total),
                        status: 2
                    }
                  
                   
                    this.$emit(CHANGESTATUS, evtObj)
                    
                }
            }

            var formData = new FormData();
            formData.append('business_type', 'qschou');
            formData.append('files', fo);

            axios.post(upUrl, formData, config).then((res) => {
                // console.log(res)
                if (res.data.code == 0) {
                    console.log(res.data)
                    var evtObj = {
                        url: res.data.data[0],
                        status: 0
                    }
                    console.log('ok')
                    this.$emit(CHANGESTATUS, evtObj)

                }else{
                    alertMsg(res.data.msg);
                    var evtObj = {
                        progress: 0,
                        status: 1
                    }
                    console.log('fail')
                    this.$emit(CHANGESTATUS, evtObj)
                }
            }).catch((res) => {
                var evtObj = {
                    progress: 0,
                    status: 1
                }
            
                this.$emit(CHANGESTATUS, evtObj)
            })
        },
    
        removeImg(e) {
            this.$emit(REMOVEIMG)
        },
        changeImg(e) {
            var files = e.currentTarget.files;
            this.$emit(ONEBEGIN)
            this.upBegin(files[0]);
   
        },
        showBigImg(e) {
            console.log(1133)
            // var files = e.currentTarget.files;
            this.$emit(SHOWBIGIMG)
        
        }
    },
   
    components: {
        sprFail,
        sprProcessing,
        sprSuccess
    },
    created() {

        if (this.fileObj) {
            this.upBegin(this.fileObj);
        }
    }
}
</script>

<style lang="less" scoped>


.san{

	position:absolute;
    left: 0;
    top:0;
	width: 32px;
	height: 32px;
	// border:1px solid #000;
	border-radius:16px;
    overflow: hidden;
	// margin:0 15px 15px 0;

}
.content{
	position:relative;
	overflow: hidden;
	// border-radius:4px;	
	height: 100%;
}


.close-btn{
	position:absolute;
	right: -15px;
	top:-15px;
	width: 30px;
	height: 30px;
	background: url(./assets/icon-close.png) no-repeat;
	background-position:center center;
}
</style>