<template>
	<div class="up-fail">
		<!-- <img src="https://static2.qschou.com/img/v8/common/grey.png" alt=""> -->
		<!-- <img class="icon-refresh" src="./assets/icon-recycle.png" alt=""> -->
		<!-- <p class="tip">上传失败</p> -->
		<input type="file" name="file" accept="image/png, image/jpg, image/jpeg"  v-on="$listeners">
	</div>
</template>

<script>
export default {

  name: 'spr-fail',

  data () {
    return {

    }
  }
}
</script>

<style lang="less" scoped>

.up-fail{
	position:absolute;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
	background-color:#FFF;
	input[type="file"]{
		position:absolute;
		left: 0;
		top:0;
		width:100%;
		height: 100%;
		opacity: 0;
		z-index: 1;
	}
}

.icon-refresh{
	width: 29px;
	height: 28px;
	position:absolute;
	left: 50%;
	top: 50%;
	margin-left: -14px;
	margin-top: -18px;
}
.tip{
	position:absolute;
	bottom:0;
	width: 100%;
	background-color: rgba(0,0,0,0.4);
	height: 14px;
	padding:1px 0;
	margin: 0;
	text-align: center;
	font-size: 12px;
	line-height: 12px;
	color:#FFF;
}
</style>