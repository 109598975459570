<template>
    <div class="preview" 
        v-if="show"
        @click="onClick">
        <img v-lazy="image"
            class="fullscreen-image">
    </div>
</template>

<script>
export default {
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        image: {
            type: String,
            default: ''
        },
    },
    watch: {
        show(val) {
            const $body = document.body;
            if (val) {
                $body.style = 'overflow: hidden;';
            } else {
                $body.style = '';
            }
        }
    },
    methods: {
        onClick() {
            this.$emit('close');
        }
    }
}
</script>

<style scoped>
.preview {
    z-index: 9999;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,.9);
    color: white;
}

.fullscreen-image {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}
</style>