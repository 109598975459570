<template>
    <div v-if="user_nickname" class="page-setting">
        <avatar :pic="user_avatar" />
        <nickname @changeName="changeName" :txt="user_nickname" />
        <phone :txt="user_phone" />
        <xieyi />
        <div style="margin-top: 32px">
            <span class="loginout" @click="showModalLoginOut">退出登录</span>
        </div>
        <modalLoginOut @confirm="loginOut" :show.sync="modalLoginOutDisplay"/>
    </div>
</template>

<script>
import avatar from './cpt/avatar.vue';
import nickname from './cpt/nickname.vue';
import xieyi from './cpt/xieyi.vue';
import phone from './cpt/phone.vue';
import modalLoginOut from './cpt/modal-loginout.vue'
import { authChecker, shareCtrl } from '@/common/js/mmcModule';
import api from './api';

export default {
    data() {
        return {
            user_avatar: '',
            user_nickname: '',
            user_phone: '',
            modalLoginOutDisplay:false,
        };
    },
    components: {
        avatar,
        nickname,
        phone,
        modalLoginOut,
        xieyi,
    },
    created() {
        authChecker.check({ notneedphone: 1 }, (res) => {
            showLoading('加载中');
            console.log(api);
            api.getInfo().then((res) => {
                hideLoading();
                console.log(res);
                this.user_avatar = res.data.face_url;
                this.user_nickname = res.data.nick_name;
                this.user_phone = res.data.phone;
            });
            shareCtrl.init();
        });
    },
    methods: {
        changeName(s) {
            console.log(s, 'dfdf');
            this.user_nickname = s;
        },
        showModalLoginOut(){
            this.modalLoginOutDisplay = true;
        },
        loginOut() {
            api.loginOut().then((res) => {
                if (res.code == 0) {
                    alertMsg('已退出');
                    setTimeout(() => {
                        // this.$router.replace('/fund/home');
                        location.replace(`${window.BasePath}passport/index.html?passport_scene=1&logout=1&redirect_url=https%3A%2F%2Fm.jjisuchou.netFfund%2Fmy`)
                    },2000);
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.page-setting {
    background-color: #eee;
    height: 100vh;
}
.loginout {
    color: #089fff;
    text-decoration: underline;
}
</style>