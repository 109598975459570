<template>
	<div class="up-processing">
        <!-- <img src="./assets/logo-gary.png" alt=""> -->
		<!-- <iconUploadRotate class="icon-uploading-rotate"></iconUploadRotate> -->
		<!-- <tips-progress class="tips-progress" :value="progress"></tips-progress> -->
	</div>
</template>

<script>
import tipsProgress from './tips-progress.vue'
import iconUploadRotate from './icon-rotate.vue';
export default {

  name: 'spr-processing',

  data () {
    return {

    }
  },
  props:['progress'],
  components:{
    tipsProgress,iconUploadRotate
  }
}
</script>

<style lang="less" scoped>
.up-processing{
  position:absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-color:#FFF;
}
.icon-uploading-rotate{
  position:absolute;
  left: 50%;
  top:50%;
  margin-top: -4px;
}
.tips-progress{
  position:absolute;
  bottom:0px;
}
</style>
