<template>
    <div class="list-item">
        <div class="item-txt">绑定手机号</div>
        <div v-if="txt" class="item-miaoshu"  @click="toBind">{{ txt }}</div>
        <div v-else class="item-bindphone" @click="toBind">点击去绑定</div>
        <img class="item-arrow" src="./icon-arrow.png" alt="" />
    </div>
</template>

<script>
export default {
    props: ['txt'],
    methods: {
        toBind() {
            this.$router.push('/fund/my/bindPhone');
        },
    },
};
</script>

<style lang="less" scoped>
.list-item {
    display: flex;
    padding: 13px 16px;
    background-color: #fff;
    align-items: center;
    .item-miaoshu {
        color: #999;
        font-size: 16px;
        margin-right: 15px;
    }
    .item-bindphone {
        color: #089fff;
        font-size: 16px;
        margin-right: 15px;
    }
    .item-txt {
        font-size: 14px;
        color: #333;
        flex: 1;
        text-align: left;
        line-height: 14px;
        // margin-left: 10px;
    }
    .item-arrow {
        width: 7px;
        height: 10px;
    }
}
</style>