<template>
    <div>
        <div class="list-item" @click="showModal">
            <div class="item-txt">昵称</div>
            <div class="item-miaoshu">{{ txt }}</div>
            <img class="item-arrow" src="./icon-arrow.png" alt="" />
        </div>
        <modal-mdf-name @confirm="submitData" :show.sync="modalDisplay" :txt="txt" />
    </div>
</template>

<script>
import modalMdfName from './modal-mdf-name.vue';
import api from '../api'
export default {
    data() {
        return {
            modalDisplay: false,
        };
    },
    props: ['txt'],
    components: {
        modalMdfName,
    },
    methods: {
        showModal(){
            this.modalDisplay = true;
        },
        submitData(s){
            
            api.changeName(s).then(res=>{
                if(res.code==0){
                    alertMsg('修改成功')
                    this.modalDisplay = false;
                    this.$emit('changeName',s)
                }
            })

            
        }
    },
};
</script>

<style lang="less" scoped>
.list-item {
    display: flex;
    padding: 13px 16px;
    background-color: #fff;
    align-items: center;
    .item-miaoshu {
        color: #999;
        font-size: 16px;
        margin-right: 15px;
    }
    .item-txt {
        font-size: 14px;
        color: #333;
        flex: 1;
        text-align: left;
        line-height: 14px;
        // margin-left: 10px;
    }
    .item-arrow {
        width: 7px;
        height: 10px;
    }
}
</style>