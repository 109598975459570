import mmcAjax from "@/common/js/mmcAjax"
export default {
    getInfo(){
        let url = `${window.APIBasePath}mmc/passport/user-center`
        
        return mmcAjax({
            method: 'get',
            url,
          })
    },
    changeName(s){
        let url = `${window.APIBasePath}mmc/passport/update-userinfo`
        
        return mmcAjax({
            method: 'post',
            url,
            data:{
                nick_name:s
            }

          })
    },

    changeAvatar(s){
        let url = `${window.APIBasePath}mmc/passport/update-userinfo`
        
        return mmcAjax({
            method: 'post',
            url,
            data:{
                face_url:s
            }

          })
    },
    loginOut(){
        let url = `${window.APIBasePath}mmc/passport/logout`
        
        return mmcAjax({
            method: 'post',
            url,
          })
    }
}