<template>
    <div v-show="show" class="mmc-modal-simple dialog" ref="customModal">
        <div class="alert-sprite">
            <section class="header">修改昵称</section>

            <section class="body"  >
                <input class="new-name" type="text" name="" id="" v-model="newname">
            </section>
            <section
                :class="{ footer: true, hairline: true, top: showCancelButton }"
            >
                <div
                    v-if="showCancelButton"
                    class="btn-refuse"
                    @click="cancel()"
                >
                    取消
                </div>
                <div
                    class="btn-confirm"
                    :class="showCancelButton ? 'hairline left' : ''"
                    @click="confirm()"
                >
                    提交
                </div>
            </section>
        </div>
    </div>
</template>

<script>
const CANCEL = 'cancel';
const CONFIRM = 'confirm';
export default {
    name: 'mmc-modal-simple',
    data: function () {
        return {
            newname:''
        };
    },
    props: {
        txt:'',
        cancelText: {
            type: String,
            default: '取消',
        },
        confirmText: {
            type: String,
            default: '确定',
        },
        showCancelButton: {
            type: Boolean,
            default: true,
        },
        title: String,
        show: false,
    },
    watch: {
        show: function (argNew) {
            // console.log(234234, 33, this.$refs.customModal, argNew);
            if (argNew == true) {
                this.newname = '';
                // console.log(this.getMaxZIndex(),999)
                this.$refs.customModal.style.zIndex = this.getMaxZIndex();
            }
        },
    },
    methods: {
        confirm() {
            if(this.newname.trim()==''||this.newname.length>13){
                alert('昵称不能为空或大于13个字符')
                return 
            }
            // this.$emit('update:show', false);
            this.$emit(CONFIRM,this.newname);
            
        },
        cancel() {
            this.$emit('update:show', false);
            this.$emit(CANCEL);
        },
        getMaxZIndex() {
            // console.log([...document.all].length,334433,document.all.length,Array.from(document.body.querySelectorAll('*')).length)
            let arr = Array.from(document.body.querySelectorAll('*')).map(
                (e) => {
                    return Number(window.getComputedStyle(e).zIndex) || 0;
                }
            );
            return arr.length ? Math.max(...arr) + 1 : 0;
        },
    },
    components: {},
    mounted() {
        // console.log(this.$el, 11);
    },
};
</script>

<style lang="less" scoped>
.mmc-modal-simple {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .alert-sprite {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        padding: 0 25px;
        // position: fixed;
        // top: 50%;
        // left: 50%;
        // transform: translate3d(0, -100%, 0);
        transform: translateY(-10px);

        text-align: center;
        width: 80%;
        background-color: #fff;
        border-radius: 5px;
        max-height: 70%;

        transition: 3s;
        // transform: translate3d(-50%, -50%, 0);
    }
    .header {
        margin-top: 20px;
        font-size: 16px;
        text-align: center;
    }
    .body {
        margin-top: 26px;
        flex: 1;
        // font-size: 16px;
        color: #666;
        position: relative;
        padding-bottom: 25px;
        line-height: 1.5em;
        overflow-y: scroll;
        text-align: left;
        flex: auto;
        -webkit-overflow-scrolling: touch;
        white-space: pre-wrap;
        text-align: justify;
    }
    .new-name{
        width: 100%;
        padding: 14px 16px;
        border:none;
        background-color: #EEE;
    }
    .footer {
        display: flex;
        // flex-shrink: 0;
        margin: 10px -25px 0;
        justify-content: center;
        align-items: center;
        justify-content: center;
        justify-items: center;

        .btn-refuse {
            // flex: 0.5;
            padding: 11px;
            text-align: center;
            font-size: 17px;
            width: 50%;
        }
        .btn-confirm{
            padding: 11px;
            text-align: center;
            font-size: 17px;
            width: 50%;
        }

        .btn-confirm2 {
            flex: 0.5;
            // padding: 11px 15px;

            // text-align: center;
            // font-size: 17px;
            // color: #ff9300;
            // height: 50px;

            // display: block;
            // width: 100%;
            height: 100%;
            background: #efefef;
            line-height: 1;
            font-size: 18px;
            color: #251a1a;
            text-align: center;
            
            border: none;
            padding: 15px 0;
            color: #999;
            pointer-events: none;
            border-radius: 5px;
            background-image: linear-gradient(90deg, #40b9ff 0%, #009bff 100%);
            box-shadow: 0 2px 15px 0 rgba(0, 155, 255, 0.4);
            color: #ffffff;
            pointer-events: auto;
        }
    }
}
</style>


