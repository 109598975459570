<template>
    <div>
        <div class="list-item" @click="toUrl('zhuce')">
            <div class="item-txt">用户注册协议</div>
            <img class="item-arrow" src="./icon-arrow.png" alt="" />
        </div>
        <div class="list-item" @click="toUrl('xieyi')">
            <div class="item-txt">隐私政策</div>
            <img class="item-arrow" src="./icon-arrow.png" alt="" />
        </div>
    </div>
</template>

<script>
export default {
    props: ['txt'],
    methods: {
        toUrl(s) {
            this.$router.push('/fund/contentSite?contentID='+s);
        },
    },
};
</script>

<style lang="less" scoped>
.list-item {
    display: flex;
    padding: 13px 16px;
    background-color: #fff;
    align-items: center;
    .item-miaoshu {
        color: #999;
        font-size: 16px;
        margin-right: 15px;
    }
    .item-bindphone {
        color: #089fff;
        font-size: 16px;
        margin-right: 15px;
    }
    .item-txt {
        font-size: 14px;
        color: #333;
        flex: 1;
        text-align: left;
        line-height: 14px;
        // margin-left: 10px;
    }
    .item-arrow {
        width: 7px;
        height: 10px;
    }
}
</style>
