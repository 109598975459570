<template>
	<div class="up-success" v-on="$listeners">
		<!-- <img src="upImg" alt="" > -->
		<img v-lazy="upImgThumb" alt="" >
		<!-- <p class="tip">上传成功</p> -->
	</div>
</template>

<script>
export default {

  name: 'spr-success',

  data () {
    return {

    }
  },
  props:{
  	upImg:{
  		type:String,
  		default:''
  	}
  },
  computed:{
  	upImgThumb:function() {
  	
  		// return this.upImg?this.upImg+'':''
		return this.upImg?this.upImg+'?x-image-process=style/thumb.png':''
  	}
  }
}
</script>

<style lang="less" scoped>
.up-success{
	position:absolute;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
	background-color:#FFF;
	border-radius: 16px;
}


.tip{
	position:absolute;
	bottom:0;
	width: 100%;
	background-color: rgba(0,0,0,0.4);
	height: 14px;
	padding:1px 0;
	margin: 0;
	text-align: center;
	font-size: 12px;
	line-height: 12px;
	color:#FFF;
}
</style>